import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../utils/animations';

const HomeContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 120px);
  min-height: calc(100vh - 120px);
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ItemContainer = styled.div`
  display: flex;
  width: 70%;
  height: 30%;
  margin: 1rem;
  background-color: white;
  border-radius: 6px;
  animation: ${fadeIn} 0.5s ease-in-out;
  animation-fill-mode: both;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    transition: 0.7s;
  }
`;

const Image = styled.img`
  width: 50%;
  height: 50vh;
  object-fit: cover;
`;

const ContentContainer = styled.div`
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  align-self: center;
`;

const Title = styled.text`
  color: ${(props) => props.theme.nav};
  font-size: 3rem;
  font-weight: 600;
  margin: 1rem;
  text-align: ${(props) => props.align};
`;

const Description = styled.text`
  color: ${(props) => props.theme.nav};
  font-size: 1rem;
  margin: 1rem;
  line-height: 1.5;
`;

function About() {
  return (
    <HomeContainer>
      <ItemContainer>
        <Image
          src={require('../../assets/beliefs.jpg')}
          alt="Image 1"
          style={{
            borderTopLeftRadius: '6px',
            borderBottomLeftRadius: '6px',
          }}
        />
        <ContentContainer>
          <Title>Beliefs</Title>
          <Description>
            The Sikh worldview centers around the idea of oneness. Sikhs believe
            that people of all faiths worship one Divine Being who created this
            world and lives within it. The notion of divine presence leads to
            the belief that the Divine is equally present in all people, and
            that, therefore, every human being is equal in the eyes of God.
          </Description>
        </ContentContainer>
      </ItemContainer>
      <ItemContainer>
        <ContentContainer>
          <Title align={'end'}>Identity</Title>
          <Description>
            Since the formative moments of the tradition, Sikhs have maintained
            a physical identity that makes them stand out in public, even in the
            context of South Asia. This identity includes five articles of faith
            — kesh (unshorn hair), kanga (small comb), kara (steel bracelet),
            kirpan (religious article resembling a knife), and kachera
            (soldier-shorts) — and distinguishes someone who has formally
            committed to the values of the faith by accepting initiation.
          </Description>
        </ContentContainer>
        <Image
          src={require('../../assets/identity.jpg')}
          alt="Image 1"
          style={{
            borderTopRightRadius: '6px',
            borderBottomRightRadius: '6px',
          }}
        />
      </ItemContainer>
      <ItemContainer>
        <Image
          src={require('../../assets/history.jpg')}
          alt="Image 1"
          style={{
            borderTopLeftRadius: '6px',
            borderBottomLeftRadius: '6px',
          }}
        />
        <ContentContainer>
          <Title>History</Title>
          <Description>
            The founder of the Sikh tradition, Guru Nanak, was born in the
            region of Punjab, South Asia, in 1469 CE. He lived a life of
            spirituality, service, and honesty, and the disciples who began to
            follow his teachings came to be known as Sikhs. The Sikh
          </Description>
        </ContentContainer>
      </ItemContainer>
      <Title
        style={{
          color: 'transparent',
        }}
      >
        .
      </Title>
    </HomeContainer>
  );
}

export default React.memo(About);
