export const initialState = {
  theme: 'light',
};

export const themeReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME':
      console.log('action.theme', state);
      return {
        ...state,
        theme: state.theme === 'light' ? 'dark' : 'light',
      };

    case 'SET_THEME':
      console.log('action.type', 'SET_THEME');
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
