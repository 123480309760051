const fonts = {
  fonts: 'Poppins',
};

export const lightTheme = {
  ...fonts,
  background: '#F3F3F3',
  primary: '#6e88ad',
  secondary: '#6c757d',
  text: '#FFF',
  nav: '#2b3441',
  orange: '#f60',
  black: '#000',
};

export const darkTheme = {
  ...fonts,
  background: '#363537',
  text: '#fff',
  primary: 'darkblue',
  secondary: '#6c757d',
  nav: '#fff',
  orange: '#ff6f00',
  black: '#000',
};
