import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../utils/animations';

import donations from './data.json';

const HomeContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 120px);
`;

const Container = styled.div`
  padding: 20px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 10px;
`;

const Card = styled.div`
  object-fit: cover;
  margin: 0.5%;
  cursor: pointer;
  padding: 10px;
  animation: ${fadeIn} 0.5s ease-in-out;
  animation-fill-mode: both;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  }
  ${({ index }) => `
  animation-delay: ${index * 200}ms;
`}
`;

const Header = styled.text`
  color: black;
  font-size: 2rem;
`;
const Item = styled(Header)`
  font-size: 1.5rem;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Info = styled(Header)`
  font-size: 1rem;
`;

function Donations() {
  console.log('Donations', 'sadasdasda');
  return (
    <HomeContainer>
      <Container>
        {donations.map((item, index) => (
          <Card key={index} style={{ marginBottom: '20px', color: 'black' }}>
            <Header>{item.title}</Header>
            {item.donation.map((donationItem, donationIndex) => (
              <ItemContainer key={donationIndex}>
                <ContentContainer>
                  <Item>{donationItem.title}</Item>
                  <Item>{donationItem.price}</Item>
                </ContentContainer>
                {donationItem.info && <Info>({donationItem.info})</Info>}
              </ItemContainer>
            ))}
          </Card>
        ))}
      </Container>
    </HomeContainer>
  );
}

export default React.memo(Donations);
