import styled from 'styled-components';

const Container = styled.div`
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.color};
  align-items: center;
  max-height: 60px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
`;

const Text = styled.text`
  color: ${(props) => props.theme.text};
`;

function Footer() {
  return (
    <Container>
      <Text>&copy; 2024 Blue Mountain Gurudwara. All rights reserved.</Text>
      <Text>362 SCHUBERT RD, BETHEL, PA 19507</Text>
    </Container>
  );
}

export default Footer;
