import { keyframes } from 'styled-components';

export const fadeInOnly = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const fadeInTransform = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const scaleAnimation = keyframes`
    0% {
    transform: scale(1);
    }
    50% {
    transform: scale(0.9);
    }
    100% {
    transform: scale(1);
    }
`;
