import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../utils/animations';

const HomeContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 120px);
  min-height: calc(100vh - 120px);
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ItemContainer = styled.div`
  display: flex;
  width: 70%;
  height: 30%;
  margin: 1rem;
  background-color: white;
  border-radius: 6px;
  animation: ${fadeIn} 0.5s ease-in-out;
  animation-fill-mode: both;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    transition: 0.7s;
  }
`;

const Image = styled.img`
  width: 50%;
  height: 50vh;
  object-fit: cover;
`;

const EduImage = styled.img`
  width: 70%;
  object-fit: cover;
  border-radius: 6px;
  margin-top: 1rem;
`;

const ContentContainer = styled.div`
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  align-self: center;
`;

const Title = styled.text`
  color: ${(props) => props.theme.nav};
  font-size: 3rem;
  font-weight: 600;
  margin: 1rem;
  text-align: ${(props) => props.align};
`;

const Description = styled.text`
  color: ${(props) => props.theme.secondary};
  font-size: 1rem;
  margin: 1rem;
  line-height: 1.5;
`;

function Educational() {
  return (
    <HomeContainer>
      <ItemContainer>
        <Image
          src={require('../../assets/7.jpg')}
          alt="was"
          style={{
            borderTopLeftRadius: '6px',
            borderBottomLeftRadius: '6px',
          }}
        />
        <ContentContainer>
          <Title>Who Are Sikhs</Title>
          <Description>
            The Sikh tradition, known in the Punjabi language as Sikhi, is a
            relatively young tradition that was founded over 500 years ago in
            the Punjab region of South Asia. There are more than 25 million
            Sikhs around the world, which makes Sikhism the world’s fifth
            largest religion. Sikhs first came to the United States in the late
            1800s and there are an estimated 500,000 Sikhs living in America
            today.
          </Description>
        </ContentContainer>
      </ItemContainer>
      <EduImage src={require('../../assets/edu1.jpg')} alt="edu 1" />
      <EduImage src={require('../../assets/edu2.jpg')} alt="edu 2" />
      <Title
        style={{
          color: 'transparent',
        }}
      >
        .
      </Title>
    </HomeContainer>
  );
}

export default React.memo(Educational);
