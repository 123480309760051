import styled from 'styled-components';
import bg_main from '../../assets/bm_main.jpg';
import { fadeInOnly, fadeInTransform } from '../../utils/animations';

const HomeContainer = styled.div`
  background: ${(props) => props.theme.background};
  flex: 1;
`;

const BackgroundImage = styled.div`
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 120px);
  background-image: url(${bg_main});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: ${fadeInOnly} 0.5s ease-in-out;
  animation-fill-mode: both;
`;

const Title = styled.text`
  color: ${(props) => props.theme.text};
  font-size: 2.5rem;
  font-weight: 600;
  margin: 1rem;
  align-text: center;
  white-space: pre-wrap; /* This ensures that \n is respected */
  align-self: flex-end;
  position: absolute;
  bottom: 10%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${fadeInTransform} 3s ease-in-out;
`;

const Image = styled.img`
  width: 10%;
  height: 10vh;
  object-fit: contain;
  margin: 2rem;
  animation: ${fadeInTransform} 3s ease-in-out;
`;

function Home() {
  return (
    <HomeContainer>
      <BackgroundImage>
        <Title>WELCOME TO THE HOME OF{'\n'} BLUE MOUNTAIN GURUDWARA</Title>
        <Image src={require('../../assets/bm_logo.png')} alt="logo" />
      </BackgroundImage>
    </HomeContainer>
  );
}

export default Home;
