import React, { useReducer, useMemo } from 'react';
import { themeReducer, initialState } from '../reducers/ThemeReducer';

const ThemeContext = React.createContext(initialState);
ThemeContext.displayName = 'ThemeContext';

const ThemeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(themeReducer, initialState);

  const setTheme = (tv) => dispatch({ type: 'SET_THEME', theme: tv });

  const toggleTheme = () => dispatch({ type: 'TOGGLE_THEME' });

  const value = useMemo(
    () => ({
      ...state,
      setTheme,
      toggleTheme,
    }),
    [state]
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within ThemeProvider');
  }
  return context;
};

export default ThemeProvider;
