import styled from 'styled-components';
import { fadeIn } from '../../utils/animations';
import React from 'react';

const HomeContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 120px);
  min-height: calc(100vh - 120px);
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 70%;
  aligh-self: center;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 6px;
  animation: ${fadeIn} 0.5s ease-in-out;
  animation-fill-mode: both;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    transition: 0.7s;
    width: 31%;
    height: 71%;
  }
`;

const Title = styled.text`
  color: ${(props) => props.theme.nav};
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem;
  align-text: center;
`;

const Image = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
`;

const Description = styled.text`
  color: ${(props) => props.theme.nav};
  font-size: 1rem;
  text-align: center;
  margin: 1rem;
  font-weight: bold;
`;

function Programs() {
  return (
    <HomeContainer>
      <ItemContainer>
        <Title>Gurmat Sangeet Classes</Title>
        <Image src={require('../../assets/7.jpg')} alt="Image 1" />
        <Description>
          Gurmat Sangeet Classes are held every Sunday from 9:00 AM to 12:00 PM.
        </Description>
      </ItemContainer>
      <ItemContainer>
        <Title>Gurudwara Sahib Sunday Program</Title>
        <Image src={require('../../assets/1.jpeg')} alt="Image 1" />
        <Description>
          Sukhmani Sahib Path: 9:00 - 11:00 AM Keertan: 11:00 AM - 1:00 PM
          Samapti & Langar: 1:00 PM onwards
        </Description>
      </ItemContainer>
      <ItemContainer>
        <Title>Week Day Program</Title>
        <Image src={require('../../assets/5.jpeg')} alt="Image 1" />
        <Description>
          Nitnem: 8AM - 9 AM Rehraas Sahib & Sukhasan: 5PM - 6PM
        </Description>
      </ItemContainer>
    </HomeContainer>
  );
}

export default React.memo(Programs);
