import styled from 'styled-components';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const GMAP_KEY = process.env.REACT_APP_GMAP_KEY;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const mapContainerStyle = {
  width: '70%',
  height: '100%',
  borderRadius: '6px',
};

const center = {
  lat: 40.497167,
  lng: -76.210099,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

function Map() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GMAP_KEY,
  });

  if (loadError) return <div>Error loading map</div>;
  if (!isLoaded) return <div>Loading map...</div>;
  return (
    <MapContainer>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={center}
        options={options}
      >
        <Marker position={center} />
      </GoogleMap>
    </MapContainer>
  );
}

export default Map;
