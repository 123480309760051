import { useState } from 'react';
import styled from 'styled-components';
import urls from '../../utils/urls';
import { isValidEmail } from '../../utils/stringUtils';

const EmailContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 4rem;
`;

const Input = styled.input`
  width: 30%;
  height: 2rem;
  margin: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.orange};
  }
`;

const Textarea = styled.textarea`
  width: 63%;
  height: 6rem; /* Increased height */
  min-height: 6rem; /* Minimum height */
  margin: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  resize: vertical; /* Allows the user to resize the height */
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.orange};
  }
`;
const Button = styled.button`
  width: 10rem;
  height: 3rem;
  margin: 1rem;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.primary};
  color: white;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 3rem;
  align-self: center
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.nav};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
`;

function Email() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    comment: '',
  });

  const handleSubmit = async () => {
    if (!formData.name || !formData.email || !formData.comment) {
      alert('Please fill out all fields');
      return;
    }
    if (!isValidEmail(formData.email)) {
      alert('Invalid email');
      return;
    }

    const response = await fetch(urls.sendEmail, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert('Email sent successfully');
    } else {
      alert('Failed to send email');
    }
  };

  return (
    <EmailContainer>
      <Row>
        <Input
          type="text" // Add input type as "text"
          placeholder="Name*"
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          value={formData.name}
          minLength={3} // Add minLength prop to enforce minimum length
          style={{ borderColor: formData.name.length < 3 ? 'red' : '#ccc' }} // Change border color based on length
        />
        <Input
          placeholder="Email*"
          type="email"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          value={formData.email}
          minLength={5} // Add minLength prop to enforce minimum length
          style={{
            borderColor: !isValidEmail(formData.email) ? 'red' : '#ccc',
          }} // Change border color based on length
        />
      </Row>
      <Row>
        <Textarea
          type="text"
          placeholder="Comment*"
          onChange={(e) =>
            setFormData({ ...formData, comment: e.target.value })
          }
          value={formData.comment}
          minLength={10} // Add minLength prop to enforce minimum length
          style={{ borderColor: formData.comment.length < 10 ? 'red' : '#ccc' }} // Change border color based on length
        />
      </Row>
      <Row>
        <Button onClick={handleSubmit}>SUBMIT</Button>
      </Row>
    </EmailContainer>
  );
}
export default Email;
