import styled from 'styled-components';
import { fadeInOnly } from '../../utils/animations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Email from './email';
import Map from './map';
import React from 'react';

const HomeContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 120px);
`;
const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-bottom: 5rem;
  padding-top: 4rem;
`;

const Title = styled.text`
  color: ${(props) => props.theme.primary};
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem;
  align-slef: center;
`;

const TitleBlue = styled.text`
  color: ${(props) => props.theme.primary};
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  text-align: center;
  letter-spacing: 0.3rem;
`;
const Description = styled.text`
  color: ${(props) => props.theme.secondary};
  font-size: 1rem;
  margin: 1rem;
  align-slef: center;
  text-align: center;
  line-height: 1.5;
  white-space: pre-wrap; /* This ensures that \n is respected */
`;

const ContactContainer = styled.div`
  height: 40vh;
  width: 40vh;
  background-color: #fff;
  display: flex;
  margin: 1rem;
  flex-direction: column;
  border-radius: 6px;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
  }
  align-items: center;
  animation: ${fadeInOnly} 0.9s ease-in-out;
  animation-fill-mode: both;
`;

const IconContainer = styled.div`
  height: 20%;
  width: 20%;
  background-color: ${(props) => props.theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 1rem;
`;
const MapContainer = styled.div`
  height: auto;
  width: 100%;
`;

const iconStyle = {
  height: '50%',
  width: '50%',
};

function Contact() {
  return (
    <HomeContainer>
      <InfoContainer>
        <ContactContainer>
          <IconContainer>
            <FontAwesomeIcon icon="location-pin" style={iconStyle} />
          </IconContainer>
          <Title>Address</Title>
          <Description>362 Schubert Rd, Bethel, PA 19507</Description>
        </ContactContainer>
        <ContactContainer>
          <IconContainer>
            <FontAwesomeIcon icon="phone" style={iconStyle} />
          </IconContainer>
          <Title>Phone</Title>
          <Description>(717) 933-8223</Description>
        </ContactContainer>
        <ContactContainer>
          <IconContainer>
            <FontAwesomeIcon icon="clock" style={iconStyle} />
          </IconContainer>
          <Title>Hours</Title>
          <Description>Monday - Saturday 7:00 AM - 6:00 PM</Description>
        </ContactContainer>
      </InfoContainer>
      <MapContainer>
        <Map />
      </MapContainer>
      <TitleBlue>EMAIL US</TitleBlue>
      <Email />
    </HomeContainer>
  );
}

export default React.memo(Contact);
