import React, { useState } from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../utils/animations';

const HomeContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 120px);
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.3%;
  padding-bottom: 0.3%;
  jsutify-content: center;
  align-items: center;
  align-content: center;
`;

const Image = styled.img`
  width: 24%;
  height: 50vh;
  object-fit: cover;
  margin: 0.5%;
  cursor: pointer;
  animation: ${fadeIn} 0.5s ease-in-out;
  animation-fill-mode: both;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  }
  ${({ index }) => `
  animation-delay: ${index * 200}ms;
`}
`;

const FullScreenModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FullScreenImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.5s ease-in-out;
  animation-fill-mode: both;
  ${({ index }) => `
  animation-delay: ${index * 100}ms;
`}
`;

const ArrowButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const LeftArrow = styled(ArrowButton)`
  left: 10px;
`;

const RightArrow = styled(ArrowButton)`
  right: 10px;
`;

function Gallery() {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const images = [
    require('../../assets/bm_main.jpg'),
    require('../../assets/1.jpeg'),
    require('../../assets/2.jpg'),
    require('../../assets/3.jpeg'),
    require('../../assets/4.jpeg'),
    require('../../assets/7.jpg'),
    require('../../assets/8.jpg'),
    require('../../assets/9.jpg'),
    require('../../assets/10.jpg'),
    require('../../assets/11.jpg'),
    require('../../assets/12.jpg'),
    require('../../assets/13.jpg'),
  ];

  const openFullScreen = (index) => {
    setSelectedIndex(index);
  };

  const closeFullScreen = () => {
    setSelectedIndex(null);
  };

  const goToPreviousImage = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const goToNextImage = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };
  return (
    <HomeContainer>
      <ImageContainer>
        {images.map((image, index) => (
          <Image
            src={image}
            key={index}
            alt={`Image ${index + 1}`}
            index={index}
            onClick={() => openFullScreen(index)}
          />
        ))}
      </ImageContainer>
      {selectedIndex !== null && (
        <FullScreenModal onClick={closeFullScreen}>
          <LeftArrow
            onClick={(e) => {
              e.stopPropagation();
              goToPreviousImage();
            }}
          >
            &#10094;
          </LeftArrow>
          <RightArrow
            onClick={(e) => {
              e.stopPropagation();
              goToNextImage();
            }}
          >
            &#10095;
          </RightArrow>
          <FullScreenImage src={images[selectedIndex]} alt="Full Screen" />
        </FullScreenModal>
      )}
    </HomeContainer>
  );
}

export default React.memo(Gallery);
